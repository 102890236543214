
    <p-dataTable
      [dataKey]="dataKey"
      [editable]="true"
      [globalFilter]="gb"
      [paginator]="paginator"
      [lazy]="true"
      [loading]="areRecordsLoading"
      (onEditCancel)="_onEditCancel($event)"
      (onEditComplete)="_onEditComplete($event)"
      (onLazyLoad)="_onLazyLoad($event)"
      [reorderableColumns]="reorderableColumns"
      [rows]="rowLimit"
      [rowHover]="true"
      [rowsPerPageOptions]="rowsPerPage"
      [sortField]="sortColumn"
      [sortOrder]="sortOrder"
      [totalRecords]="totalRecords"
      [value]="records"
    >
      <p-header>
        <div class="ui-helper-clearfix" style="width:100%;">
          <input #gb type="text" placeholder="Global search" style="float:left;">
          <div style="float:right;">
            <app-columns-multiselect-component
              [columns]="columns"
              [selectedColumns]="columns"
              (onChange)="_onMultiselect($event)"
            >
            </app-columns-multiselect-component>
          </div>
        </div>
      </p-header>
      <p-column
        *ngFor="let column of columns"
        [editable]="column.editable"
        [field]="column.value"
        [header]="column.label"
        [hidden]="!column.is_visible"
        [style]="{'width':'100%'}"
        [sortable]="column.is_sortable"
        [filter]="column.is_filterable"
        [filterMatchMode]="column.filter_match_mode"
      >
        <ng-template let-row="rowData" pTemplate="body">
          <div [ngSwitch]="column.data_type">
          <span *ngSwitchCase="'timestamp without time zone'">
            {{row[column.value] | date:column.format_pattern}}
          </span>
            <span *ngSwitchCase="'numeric'">
            {{row[column.value] | number:column.format_pattern}}
          </span>
            <span *ngSwitchDefault>
            {{row[column.value]}}
          </span>
          </div>
        </ng-template>

      </p-column>
    </p-dataTable>