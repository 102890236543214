
    <form
      *ngIf="formFieldSettings.length > 0"
      [formGroup]="form"
      (ngSubmit)="onSubmit.emit(form.value)">
      <div class="ui-g">
        <div class="ui-g-4">
          <app-dynamic-form-element
            *ngFor="let formFieldSetting of formFieldSettings"
            [formFieldName]="formFieldSetting.form_field_name"
            [formFieldLabel]="formFieldSetting.form_field_label"
            [formGroup]="form"
          >
          </app-dynamic-form-element>
        </div>
      </div>
      <div class="ui-g">
        <div class="ui-g-12">
          <button type="submit"
                  [label]="selectedPathName"
                  pButton>
          </button>
        </div>
      </div>
    </form>