
    <div class="ui-g">
      <div class="ui-g-12">
        <app-table-component [areRecordsLoading]="areRecordsLoading$ | async"
                             [columns]="columns$ | async"
                             [records]="records$ | async"
                             [rowLimit]="rowLimit$ | async"
                             [rowOffset]="rowOffset$ | async"
                             [sortColumn]="sortColumn$ | async"
                             [sortOrder]="sortOrder$ | async"
                             [tableName]="tableName$ | async"
                             [totalRecords]="totalRecords$ | async"
                             (onEditCancel)="onEditCancel($event)"
                             (onEditComplete)="onEditComplete($event)"
                             (onPagination)="onPagination($event)"
                             (onSort)="onSort($event)"
                             (onMultiselect)="updateColumns($event)"
        >
        </app-table-component>
      </div>
    </div>