
    <div [formGroup]="formGroup">
      <label>
        {{formFieldName}}
      </label>

        <input *ngIf="formFieldName !== 'password'"
                [formControlName]="formFieldName"
                [id]="formFieldName"
                pInputText
        />

        <input *ngIf="formFieldName === 'password'"
               [formControlName]="formFieldName"
               [id]="formFieldName"
               type="password"
               pPassword
        />

    </div>
  